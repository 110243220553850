.adminbanner {
  text-align: center;
  font-size: 24px;
  background-color: black;
  color: var(--logo-blue);
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logoutbutton {
  border: none;
  height: 50px;
  width: 200px;
  font-family: var(--primary-font);
  font-size: 20px;
  background-color: var(--logo-blue);
  border-radius: 10px;
  color: black;
  cursor: pointer;
}

.logoutbutton:hover{
  background-color: var(--white);
  color: var(--black);
}