:root {
  --primary-font: 'Monteserrat', sans-serif;
  --logo-blue: #0fd4d5;
  --logo-blue-secondary: #11b7be;
  --logo-green: #29c88b;
  --dark-blue: #3388a9;
  --medium-blue: #4496b3;
  --light-blue: #daf0f1;
  --light-blue-two: #62b3c9;
  --light-blue-three: #b1eff7;
  --light-blue-four: #69c5d8;
  --green: #85aba6;
  --grey-two: #435e63;
  --grey-three: #2f2e1d;
  --grey-four: #8abec9;
  --black: #1B1E11;
  --white: #FFFFFF;
  --grey: #f4f5f7;
  --input-border: 2px solid white;
  --input-border-radius: 4px;
}

body {
  margin: 0;
  font-family: 'Monteserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  min-height: 100vh;
  max-width: 100vw;
  width: 100%;
}

code {
  font-family: 'Monteserrat', sans-serif;
}

a {
  color: var(--dark-blue);
  text-decoration: none;
}

/* Below prevents image from causing a scrollbar */
img {
  max-width: 100%;
  display: block;
}

.error-container {
  margin: 0;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

}

.error-text {
  color: red;
  font-size: 14px;
}

.tele-form-header, #tele-form-header {
  text-align: center;
  padding-bottom: 40px;
  margin: 0;
  font-weight: bold;
  font-size: 40px;
}

@media (max-width: 800px) {
  body {
    max-width: 100%;
  }

  /* Navbar Mobile */
  .navbar-container {
    max-height: 200px;
  }

  .navbar-top-container {
    display: flex;
    flex-direction: column;
    max-height: 120px;
  }

  .navbar-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-logo {
    max-width: 150%;
    max-height: 150%;
  }

  .navbar-top-link-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .navbar-links#top {
    padding: 0px 20px;
    font-weight: 600;
    color: var(--black);
    font-size: 16px;
  }

  .navbar-links#top:hover {
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    color: var(--logo-blue);
  }

  .navbar-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 80px;
    max-width: 100%;
    background-color: var(--dark-blue);
    /* background-image: linear-gradient(to bottom right, var(--dark-blue), var(--logo-blue));  */
    /* PREFERS THE ONE ABOVE */
    /* background-image: linear-gradient(to bottom left, var(--dark-blue), var(--logo-blue)); */
    padding: 0px 2%;
  }

  .navbar-links {
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    color: var(--white);
    text-wrap: wrap;
    padding: 20px;
    text-align: center;
  }

  .navbar-links#active {
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    color: var(--grey-three);
  }

  .navbar-links:hover {
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    color: var(--grey-three);
    text-align: center;
    padding: 20px;
  }


  /* Splashpage */


  .splash-bg {
    height: 35vh;
    width: 100%;
    position: relative;
    background-image: url("../../app/static/images/background.jpg");
    background-size: cover;
    background-repeat: none;
    opacity: 0.85;
    max-width: 100vw;
  }

  .splash-overlay-container {
    position: absolute;
    top: 25%;
    background-color: rgba(245, 245, 245, 0.7);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .splash-overlay {
    color: black;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    font-family: var(--primary-font);
    padding-right: 5px;
    padding-top: 10px;
  }

  .splash-overlay-sub {
    color: var(--black);
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    font-family: var(--primary-font);
    padding: 10px 0px;
    padding-right: 5px;
  }

  .splash-cards-container {
    max-width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
    background-color: var(--dark-blue);
    position: relative;
  }

  .splash-cards {
    width: 90%;
    height: 250px;
    border-radius: 5px;
    border: 1px solid var(--black);
    background-color: var(--white);
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 20% 50% 20%;
    justify-items: center;
    margin-bottom: 10px;
  }

  .splash-headers {
    grid-row-start: 2;
    text-align: center;
    position: relative;
    margin: 0;
    padding-top: 50px;
    font-size: 14px;
  }

  .splash-cards-content {
    grid-row-start: 3;
    font-family: var(--primary-font);
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    padding: 30px 15px;
    line-height: 14px;
    vertical-align: middle;
    margin: 0;
    height: 100%;
  }

  .splash-buttons {
    grid-row-start: 4;
    width: 100px;
    height: 40px;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid var(--logo-blue);
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }


  /* Footer Mobile */
  /* .footer-container{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  } */
  .footer-sections {
    width: 100%;
  }

  .footer-headers {
    font-size: 14px;
  }

  .footer-text {
    font-size: 12px;
  }

  .footer-text#lunch,
  .footer-text#shots {
    font-size: 10px;
  }

  .footer-text#hours {
    display: flex;
    flex-direction: column;
  }

  /* Services Mobile */
  .services-tabs {
    margin: 0;
    padding: 5px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .services-icons {
    padding-right: 10px;
  }


  .services-detail-container>p {
    padding: 0 40px;
    font-family: var(--primary-font);
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 0;
    font-size: 14px;
  }

  .subservice-container>h2 {
    margin: 20px 0;
    color: var(--dark-blue);
    font-size: 18px;
  }

  .subservice-container>p {
    font-family: var(--primary-font);
    font-weight: 300;
    line-height: 16px;
    margin: 0;
    margin-bottom: 20px;
    font-size: 14px;
  }

  /* Telemedicine Visits Mobile */
  .telemedicine-container {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    background-color: var(--light-blue);
  }




  .telemedicine-text-container {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    padding: 20px;
  }

  .telemedicine-text-container>h1 {
    color: var(--dark-green);
    margin: 0;
    font-size: 20px;
    font-family: var(--primary-font);
    font-weight: 500;
  }

  .tele-form-header,
  #tele-form-header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
  }

  .telemedicine-text-container>p {
    font-family: var(--primary-font);
    font-weight: 300;
    line-height: 16px;
    font-size: 14px;
  }

  .telemedicine-phone-text {
    font-weight: 400;
    line-height: 16px;
    color: var(--dark-blue);
  }

  /* School Forms */
  .forms-container {
    max-width: 100%;
    padding: 20px;
    background-color: var(--light-blue);
  }

  .forms-container>h1 {
    font-size: 20px;
    margin: 0;
    padding: 20px 0;
    font-weight: 400;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .forms-container>p {
    font-family: var(--primary-font);
    font-weight: 300;
    line-height: 16px;
    font-size: 14px;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.6);
  }

  /* Message */
  .message-toggle-label {
    display: block;
    font-weight: bold;
    font-family: var(--primary-font);
    font-size: 22px;
    color: var(--black);
    cursor: pointer;
    transition: all 0.25s ease-out;
    line-height: 50px;
    text-align: center;
  }

  .message-collapsible-content .message-content {
    text-align: center;
    font-family: var(--primary-font);
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }

  /* Contact Mobile */

  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    background-color: var(--light-blue);
  }

  .contact-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
    text-align: center;
    border-radius: 10px;
  }

  .contact-info-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  .contact-info-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0;
  }

  .contact-info-header {
    margin: 0;
    font-family: var(--primary-font);
    font-size: 14px;
  }

  .contact-info-left>p,
  .contact-info-text {
    text-align: center;
    font-family: var(--primary-font);
    font-size: 12px;
  }

  .contact-location-container>h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .contact-location-container {
    padding: 0px 15px;
  }

  .contact-location-container>p {
    margin: 0;
    line-height: 16px;
    font-size: 14px;
  }

  .contact-image-container {
    max-height: 80%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
  }

  .contact-detail-container {
    margin-top: 10px;
  }

  /* Practice Mobile */


  .practice-container>h1,
  .services-container>h1 {
    font-size: 20px;
    font-weight: 400;
  }

  .practice-card {
    --height: fit-content;
    height: calc(var(--height) + 60px);
  }

  .practice-biography-text {
    min-height: fit-content;
    line-height: 20px;
  }
}