.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  height: fit-content;
  padding-bottom: 60px;
  background-color: var(--light-blue);
}

.services-container>h1 {
  margin: 0;
  padding: 20px 0;
  font-weight: 400;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}

.services-content-container {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: fit-content;
}

.services-tabs-container {
  color: var(--white);
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(158px, 1fr));
}

.services-tabs {
  height: 40px;
  padding: 15px;
  font-family: var(--primary-font);
  font-size: 18px;
  text-wrap: wrap;
  border-bottom: 1px solid var(--light-blue);
  border-left: .5px solid var(--light-blue);
  border-right: .5px solid var(--light-blue);
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.5s;
  background-color: var(--dark-blue);
  color: var(--white);
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.services-tabs:hover {
  background-color: var(--white);
  color: var(--dark-blue);
}

.services-tabs#active {
  background-color: var(--white);
  color: var(--dark-blue);
}

.services-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--white);
  padding-bottom: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.services-detail-container>h1 {
  margin: 5px;
  padding-top: 20px;
  color: var(--black);
  text-align: center;
}

.services-detail-container>p {
  padding: 0 40px;
  font-family: var(--primary-font);
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 0;
}

.services-icons {
  color: var(--tan);
  padding-right: 10px;
}

.services-tabs:hover .services-icons {
  color: var(--light-green);

}

.services-subservices {
  margin: 20px 40px;
  border-top: 1px solid black;
}

.services-allergic-rhinitis#inactive,
.services-asthma#inactive,
.services-contact#inactive,
.services-drug#inactive,
.services-food#inactive {
  display: none
}

.subservice-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid var(--dark-blue);
}

.subservice-container>h2 {
  margin: 20px 0;
  color: var(--dark-blue)
}

.subservice-container>p {
  font-family: var(--primary-font);
  font-weight: 300;
  line-height: 30px;
  margin: 0;
  margin-bottom: 20px;
}