.adminportal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--grey);
  min-height: 60vh; 
  padding-bottom: 40px;
}

.edit-headers {
  font-weight: 400;
  font-size: 30px;
  padding: 20px 0;
  margin: 0;
}

.divider{
  display: flex;
  width: 80%;
  border-bottom: 1px solid grey;
}
.showmessage-container {
  padding: 20px 40px;
    text-align: center;
    background-color: var(--light-blue);
    width: 75%;
    border-radius: 10px;
    margin: 20px 0;
}
.showmessage-label{
  padding: 10px;
}
.showmessage-label input {
  margin-right: 10px;
  /* Add space between the radio button and the label text */
}
.showmessage-button {
  border: none;
  height: 60px;
  width: 140px;
  font-family: var(--primary-font);
  font-size: 18px;
  background-color: var(--grey-two);
  border-radius: 10px;
  color: var(--light-blue);
  cursor: pointer;
}

.showmessage-button:hover {
  background-color: #FBE557;
  color: var(--black);
}