.practice-container {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  align-items: center;
  background-color: var(--light-blue);
}

.practice-container>h1 {
  margin: 0;
  padding: 20px 0;
  font-weight: 400;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  border-radius: 10px;
}

.practice-container>p{
  font-size: 22px;
  margin: 20px 10px;
  padding-top: 20px;
  font-weight: 300;
}

.practice-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding: 20px;
  border-radius: 5px;
  justify-content: center;  
}

.practice-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: fit-content;
  padding: 20px;
  margin-top: 5px;
  /* background-color: var(--white); */
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  width: 300px;
  height: 800px;
}

.practice-image-container{
  display: flex;
  justify-content: center;
  align-content: center;
  width: 280px;
  height: 300px;
}
.practice-image {
  width: inherit;
  border-radius: 20px;
  object-fit:cover;
  object-position:top;
}

.practice-card-text {
  font-size: 16px;
  font-family: var(--primary-font);
  font-weight: 300;
  font-display: block;
  color: var(--black);
  padding: 0 10px;
}
.practice-card-text>h3{
  color: var(--grey-two);
  font-weight: 400;
  font-size: 20px;
  max-height: fit-content;
  height: 40px;
  margin-bottom: 0;
  text-align: center;
}
.practice-names {
  font-size: 20px;
  font-weight: 400;
  font-family: var(--primary-font);
  color: var(--dark-blue);
  margin: 0;
  text-align: center;
  padding-bottom: 20px;
}
.practice-biography-text{
  margin: 10px 0;
  font-size: 16px;
  line-height: 20px;
  font-display: auto;
}