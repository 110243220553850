.forms-container {
  padding: 40px 100px;
  background-color: var(--light-blue);
}

.forms-container>h1 {
  margin: 0;
  padding: 20px 0;
  font-weight: 400;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}

.forms-container>p {
  font-family: var(--primary-font);
  font-weight: 300;
  line-height: 28px;
  padding: 40px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 22px;
  margin: 0;
}