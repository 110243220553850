.message-container {
  width: 100%;
  background-color:#FBE557;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.message-toggle#collapsible {
  display: none;
}

.message-toggle-label {
  display: block;
  font-weight: bold;
  font-family: var(--primary-font);
  font-size: 24px;
  color: var(--black);
  cursor: pointer;
  transition: all 0.25s ease-out;
  line-height: 50px;
  text-align: center;
}

.message-toggle-label:hover {
  color: var(--black);
}

.message-toggle-label::before {
  content: ' ';
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: .7rem;
  transform: translateY(-2px);
  transition: transform .2s ease-out;
}

.message-collapsible-content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height .3s ease-in-out;
  background-color: #FBE557;
  /* background-color: var(--white); */
  margin: 0;
  padding: 0;
}

.message-toggle:checked+.message-toggle-label+.message-collapsible-content {
  max-height: 100vh;
}

.message-toggle:checked+.message-toggle-label::before {
  transform: rotate(90deg) translateX(-3px);
}

.message-toggle:checked+.message-toggle-label {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.message-collapsible-content .message-content {
  text-align: center;
  font-family: var(--primary-font);
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.message-content>p{
  padding: 0 20%;
  margin: 0;
  padding-bottom: 5px;
  text-align: center;
}