.footer-container{
  background-color: var(--white);
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 20px;
}

.footer-sections{
  width: 32vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
}

.footer-headers{
  margin: 0;
  font-size: 20px;
  color: var(--logo-blue-secondary);
  margin-bottom: 10px;
}

.footer-text{
  text-align: center;
  font-family: var(--primary-font);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

.footer-text>#map{
  color: var(--black);
}
.footer-text>#map:hover {
  color: var(--dark-blue);
}

.footer-text#lunch{
  color: var(--logo-blue-secondary);
  margin: 0;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 400;
}

.footer-text#shots{
  font-size: 14px;
  margin: 10px 2px;
  line-height: 16px;
}

.footer-icons{
  color: var(--logo-blue-secondary);
  padding-right: 5px;
}

.fa-location-dot{
  padding-right: 5px;
}