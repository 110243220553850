.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  background-color: var(--light-blue);
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 0;
}

.login-form-sections {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.login-form-labels {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.login-form-required-text {
  color: red;
}

.login-form-inputs {
  line-height: 50px;
  border: none;
  padding: 2px 20px;
  border-radius: 10px;
}

.login-form-button-container{
  padding: 20px 0px;
  width: 60%;
}

.login-submit-button{
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: var(--dark-blue);
  color: var(--white);
}

.login-submit-button-disabled{
width: 100%;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: gray;
  color: var(--white);
}
