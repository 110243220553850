.telemedicine-container {
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
  background-color: var(--light-blue);
}

.telemedicine-container>h1 {
  margin: 0;
  padding: 20px 0;
  font-weight: 400;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}

.telemedicine-text-container{
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  padding: 40px;
}

.telemedicine-text-container>h1 {
  color: var(--dark-green);
  margin: 0;
  font-size: 28px;
  font-family: var(--primary-font);
  font-weight: 500;
}

.telemedicine-text-container>p {
  font-family: var(--primary-font);
  font-weight: 300;
  line-height: 26px;
  font-size: 22px;
}
.telemedicine-phone-text{
  font-weight: 400;
  line-height: 26px;
  color: var(--dark-blue);
}