.createmessage-container {
  padding: 20px 40px;
  text-align: center;
  background-color: var(--light-blue);
  width: 75%;
  border-radius: 10px;
  margin: 20px 0;
}

.createmessage-input {
  width: 90%;
  height: 10vh;
  padding: 20px;
  font-family: var(--primary-font);
  border: none;
  border-radius: 10px;
}

.createmessage-header {
  font-weight: 400;
  font-size: 24px;
}

.createmessage-info {
  font-size: 18px;
  margin: 10px;
}

.createmessage-button {
  border: none;
  height: 60px;
  width: 140px;
  font-family: var(--primary-font);
  font-size: 18px;
  background-color: var(--grey-two);
  border-radius: 10px;
  color: var(--light-blue);
  cursor: pointer;
}

.createmessage-button:hover {
  background-color: #FBE557;
  color: var(--black);
}

.prevmessage-container {
  border-top: 1px solid var(--white);
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prevmessage-inner-container {
  background-color: var(--white);
  width: 100%;
  border-radius: 10px;
}