.navbar-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 200px;
  margin-top: 5px;
}

.navbar-top-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  max-width: 100%;
  padding: 10px 15px;
}

.navbar-logo-container {
  max-width: 40%;
  max-height: 100%;
}

.navbar-logo {
  max-width: 400px;
  max-height: 98px;
}
.navbar-top-link-container{
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navbar-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  max-width: 100%;
  background-color: var(--dark-blue);
  /* background-image: linear-gradient(to bottom right, var(--dark-blue), var(--logo-blue));  */
  /* PREFERS THE ONE ABOVE */
  /* background-image: linear-gradient(to bottom left, var(--dark-blue), var(--logo-blue)); */
  padding: 0px 15%;
}

.navbar-links {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 26px;
  text-decoration: none;
  color: var(--white);
}

.navbar-links#active {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 26px;
  text-decoration: none;
  color: var(--grey-three);
}

.navbar-links#top{
  padding: 0px 20px;
  font-weight: 600;
  color: var(--black);
  font-size: 26px;
}

.navbar-links#top:hover{
 font-weight: 600;
 font-size: 26px;
 text-decoration: none;
 color: var(--logo-blue); 
}

.navbar-links:hover {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 26px;
  text-decoration: none;
  color: var(--grey-three);
}