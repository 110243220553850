.provider-form-container {
  width: 50vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--light-blue);
}

/* Cancel button */
.cancel-button-container {
  display: flex;
  justify-content: flex-end;
  height: 25px;
  align-items: center;
}

i.fa-solid.fa-xmark.fa-lg {
  color: var(--dark-font-color);
  cursor: pointer;
}

i.fa-solid.fa-xmark.fa-lg:hover {
  color: var(--white);
}

/* Form Header */
.provider-form-header-container {
  display: flex;
  justify-content: center;
}

.provider-form-header {
  margin: 0;
}

.provider-form-inner-container {
  padding: 5px;
}

.provider-form-sections {
  display: flex;
  flex-direction: column;
}

.provider-form-required-text {
  font-size: 12px;
  color: red;
  margin: 0;
  padding-left: 5px;
  padding-bottom: 2px;
}

/* - Styling for form elements -  */
.provider-form-labels {
  font-size: 20px;
  color: black;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

}

.provider-form-inputs::placeholder {
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.provider-form-inputs {
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  color: black;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  padding: 12px 18px;
  font-family: var(--primary-font);
}

#textarea {
  min-height: 250px;
}

.provider-form-char-remaining-text {
  margin: 10px 0;
  font-size: 12px;
  color: var(--grey-three);
  text-align: end;
}

/* Button Styling */
.provider-form-button-container {
  width: 100%;
}

.provider-submit-button {
  background-color: var(--grey-two);
  color: white;
  border-radius: var(--input-border-radius);
  border: none;
  padding: 7px;
  cursor: pointer;
  height: 2.5em;
  border-radius: 5px;
  width: 100%;
}



.edit-provider-button {
  cursor: pointer;
  background-color: #29a5dc;
  border-radius: 24px;
  color: #FFF;
  font-size: 15px;
  font-weight: 300;
  height: 42px;
  line-height: 9px;
  padding: 0;
  position: relative;
  border: none;
  width: 100px;
}


.provider-radio-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

}

.provider-form-radio-text {
  font-size: 16px;
  color: var(--main-font-color);
  margin: 0;
}

.provider-radio-dials {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100px;
}