.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  background-color: var(--light-blue);
}

.contact-info-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 0;
}
.contact-holiday-hours {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
    text-align: center;
    border-radius: 10px;
    margin-top: 10px;
}
.contact-info-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0px 80px;
  border-right: 1px solid white;
}

.contact-info-left>p, .contact-info-text {
  text-align: center;
  font-family: var(--primary-font);
}

.contact-info-header {
  margin: 0;
  font-family: var(--primary-font);
}

.contact-info-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 100px;
  align-items: center;
}


.contact-map {
  height: 100%;
  width: 100%;
}

.contact-detail-container {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  margin: 30px 0;
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.contact-direction-container {
  display: flex;
  flex-direction: row;
  width: 100%;

}

.contact-location-container {
  width: 50%;
  padding: 5px 40px;
}

.contact-location-container>h3 {
  margin: 0;
  margin-bottom: 10px;
}

.contact-location-container>p {
  margin: 0;
  line-height: 35px;
  font-size: 18px;
}

.contact-image-container {
  max-height: 40%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
}

.contact-image-container>img {
  height: 50%;
  width: 80%;
}