.splash-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100vw;
}

.splash-bg {
  height: 80vh;
  width: 100%;
  position: relative;
  background-image: url("../../../../app/static/images/background.jpg");
  background-size: cover;
  background-position: 60% 40%;
  opacity: 0.85;
  max-width: 100vw;
}
.splash-overlay-container{
  position: absolute;
  top: 30%;
  background-color: rgba(245, 245, 245, 0.7);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.splash-overlay {
  color: black;
  font-weight: 500;
  font-size: 48px;
  margin: 0;
  font-family: var(--primary-font);
  padding-right: 40px;
  padding-top: 10px;
}

.splash-overlay-sub {
  color: var(--black);
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  font-family: var(--primary-font);
  padding: 10px 0px;
  padding-right: 40px;
}

.splash-cards-container {
  max-width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  background-color: var(--dark-blue);
  position: relative;
}

.splash-cards {
  border-radius: 5px;
  border: 1px solid var(--black);
  height: 320px;
  width: 380px;
  background-color: var(--white);
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10% 20% 50% 20%;
  justify-items: center;
}

.splash-cards:hover {
  background-color: var(--grey);
}

.splash-icon-container {
  grid-row-start: 1;
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.splash-icon-container>.fa-solid {
  color: var(--logo-blue);
}

.splash-headers {
  grid-row-start: 2;
  text-align: center;
  position: relative;
  margin: 0;
  padding-top: 40px;
}

.splash-cards-content {
  grid-row-start: 3;
  font-family: var(--primary-font);
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  padding: 10px 36px;
  line-height: 28px;
  vertical-align:middle;
  margin: 0;
  height: 100%;
}

.splash-buttons {
  grid-row-start: 4;
  width: 150px;
  height: 60px;
  text-decoration: none;
  background-color: transparent;
  border: 2px solid var(--logo-blue);
  border-radius: 5px;
  cursor: pointer;
  font-size: 22px;
}

.splash-buttons:hover {
  background-color: var(--logo-blue);
  color: var(--black);
}
.splash-staff-photo-container{
  height: 50vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px;
}
